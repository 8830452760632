/* GoldMinerGame.css */
#gameArea {
	position: relative;
	width: 100vw;
	height: 100vh;
	background-color: #222;
	padding-top: 50px;
	overflow: hidden;
	background-image: url('/public/background-space.jpg');
  }
  #scoreboard {
	position: absolute;
	top: 10px;
	right: 10px;
	color: #fff;
	font-size: 20px;
	z-index: 1000;
	display: grid;
  }
  #pauseButton {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1000;
	padding: 10px 20px;
	font-size: 18px;
  }
  #modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	background-color: rgba(0, 0, 0, 0.8);
	padding: 30px;
	color: #fff;
	font-size: 24px;
	text-align: center;
	border-radius: 10px;
	transition: transform 0.3s ease-in-out;
	z-index: 1001;
  }
  #modal.show {
	transform: translate(-50%, -50%) scale(1);
	width:100%;
  }
  #modal button {
	margin-top: 20px;
	padding: 10px 20px;
	font-size: 18px;
  }

  .cosmonautImage-center {
	background-image: url('../images/cosmonaut.svg');
	width: 100px;
	height: 89px;
	background-size: contain;
	position: absolute;
	top: 0;
	left: 50%;
	margin-top: 10px;
	transform: translate(-50%, 0);
  }
